import { React, useState, useEffect } from "react";
import {
  Form,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  Title,
  useGetOne,
} from "react-admin";
import { useInput } from "../../../../lib/Hooks";
import { ActionButton } from "../../CustomComponents/ActionButton";
import {
  Box,
  Input,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormGroup,
  Link,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
} from "@mui/material";

function saveTxtToFile(fileName, textData) {
  const blobData = new Blob([textData], { type: "text/plain" });
  const urlToBlob = window.URL.createObjectURL(blobData);

  const a = document.createElement("a");
  a.style.setProperty("display", "none");
  document.body.appendChild(a);
  a.href = urlToBlob;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(urlToBlob);
  a.remove();
}

const UtilityView = function UtilityView() {
  const [selectedCompany, updateSelectedCompany] = useInput();
  const [dataLoaderCompanyId, setDataLoaderCompanyId] = useState("");
  const [dataLoaderLoadFromEnvironment, setDataLoaderLoadFromEnvironment] =
    useState("production");
  const [companyData, setCompanyData] = useState("");
  const [benefitsRegistryCompanyData, setBenefitsRegistryCompanyData] =
    useState("");
  const [skipBenefitsRegistry, setSkipBenefitsRegistry] = useState(false);
  const [manualDataLoad, setManualDataLoad] = useState(false);
  const [scenarios, setScenarios] = useState([]);
  const [selectedScenario, setSelectedScenario] = useState("");
  const [seededCompanyInfo, setSeededCompanyInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const environment = process.env.REACT_APP_ENV;
  const loadFromEnvironments = ["staging", "tour", "production"];
  const validLoadFromEnvironments = loadFromEnvironments.filter(function (
    loadFromEnvironment
  ) {
    return loadFromEnvironment !== environment;
  });
  const currentEnvironment = process.env.REACT_APP_ENV || "development";
  const isProduction = currentEnvironment === "production";
  const urlPrefix = () => {
    // eslint-disable-next-line
    switch (dataLoaderLoadFromEnvironment) {
      case "tour":
        return "https://api.tools.benefits.tour.justworks.com";
      case "staging":
        return "https://api.tools.benefits.staging.justworks.com";
      case "development":
        return "https://api.tools.benefits.secure.crimsonsrage.com";
    }
  };
  const seedingUrlPrefix = () => {
    // eslint-disable-next-line
    switch (currentEnvironment) {
      case "production":
        return "https://api.tools.benefits.secure.justworks.com";
      case "tour":
        return "https://api.tools.benefits.tour.justworks.com";
      case "staging":
        return "https://api.tools.benefits.staging.justworks.com";
      case "development":
        return "https://api.tools.benefits.secure.crimsonsrage.com";
    }
  };
  const sanitizedDataUrl = `${urlPrefix()}/api/v0/sanitizer/company_data/${dataLoaderCompanyId}`;
  const benefitsRegistryDataUrl = `${urlPrefix()}/api/v0/benefits_registry_company_data/${dataLoaderCompanyId}`;
  const scenarioMetadataUrl = `${seedingUrlPrefix()}/api/v0/seeding/scenarios/metadata`;

  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await fetch(scenarioMetadataUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch scenarios");
        }
        const data = await response.json();
        setScenarios(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    setSkipBenefitsRegistry(
      dataLoaderLoadFromEnvironment === "staging" ||
        dataLoaderLoadFromEnvironment === "tour"
    );

    if (["staging", "tour", "development"].includes(currentEnvironment)) {
      fetchScenarios();
    }
  }, [dataLoaderLoadFromEnvironment, currentEnvironment, scenarioMetadataUrl]);

  const LoadedCompany = () => {
    const { data } = useGetOne(
      "companies",
      {
        id: dataLoaderCompanyId,
      },
      { retry: true, enabled: !!dataLoaderCompanyId }
    );
    return (
      <div>
        <strong>Loaded company: </strong>
        {data && (
          <ReferenceField
            record={data}
            source="id"
            reference="companies"
            label="Company"
            link="show"
          />
        )}
      </div>
    );
  };

  const companyLabel =
    dataLoaderCompanyId.length > 1
      ? `Company ${dataLoaderCompanyId}`
      : "your selected company";

  const saveFile = (fileName) => {
    return ({ data }) => saveTxtToFile(fileName, data);
  };

  return (
    <div>
      <Title title="Utilities" />
      <Box>
        <h4>Sanitized Data Dumper</h4>
        <Form>
          <ReferenceInput
            sort={{ field: "name", order: "ASC" }}
            label="company"
            reference="companies"
            source="name"
            perPage={15000}
          >
            <SelectInput
              onChange={updateSelectedCompany}
              source="name"
              optionText="name"
            />
          </ReferenceInput>
        </Form>
        <ActionButton
          label="Dump selected company"
          method="GET"
          resource="sanitizer"
          id={selectedCompany}
          disabled={!selectedCompany}
          callback={saveFile(`company_seed_data_${selectedCompany}.rb`)}
        />
        <br />
        <ActionButton
          label="Dump all companies"
          method="GET"
          resource="sanitizer"
          id="all"
          callback={saveFile(`company_seed_data_all.rb`)}
        />
      </Box>
      <Divider sx={{ borderWidth: 2, mt: 2 }} />
      {environment !== "production" && (
        <Box>
          <h4>Data Loader</h4>
          <div>
            Running the data loader kicks off a job that will:
            <ul>
              <li>
                Wipe all existing company-specific data for {companyLabel} from{" "}
                {environment}
              </li>
              <li>
                Load sanitized data for {companyLabel} from{" "}
                {dataLoaderLoadFromEnvironment} to {environment}
              </li>
              {!skipBenefitsRegistry && (
                <span>
                  <li>
                    Close all existing company-specifc benefits registry data
                    for {companyLabel} in staging
                  </li>
                  <li>
                    Load company-specifc benefits registry data for{" "}
                    {companyLabel} from production to staging
                  </li>
                </span>
              )}
            </ul>
          </div>
          <FormGroup sx={{ m: 1, maxWidth: 500 }}>
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel id="load-from-environment-label">
                Load From Environment
              </InputLabel>
              <Select
                label="Load From Environment"
                labelId="load-from-environment-label"
                onChange={(event) =>
                  setDataLoaderLoadFromEnvironment(event.target.value)
                }
                value={dataLoaderLoadFromEnvironment}
              >
                {validLoadFromEnvironments.map((environment, index) => {
                  return (
                    <MenuItem key={index} value={environment}>
                      {environment}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <Input
                placeholder="Company UUID"
                type="text"
                onChange={(event) => setDataLoaderCompanyId(event.target.value)}
              />
            </FormControl>
            <FormControlLabel
              sx={{ m: 1, minWidth: 200 }}
              size="small"
              control={
                <Checkbox
                  checked={skipBenefitsRegistry}
                  onChange={(event) =>
                    setSkipBenefitsRegistry(!skipBenefitsRegistry)
                  }
                />
              }
              label="Skip Benefits Registry"
            />
            <FormControlLabel
              sx={{ m: 1, minWidth: 200 }}
              size="small"
              control={
                <Checkbox
                  checked={manualDataLoad}
                  onChange={(event) => setManualDataLoad(!manualDataLoad)}
                />
              }
              label="Load data manually"
            />
            {manualDataLoad && (
              <FormGroup>
                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                  <Link target="_blank" href={sanitizedDataUrl}>
                    Get Sanitized Data From {dataLoaderLoadFromEnvironment}
                  </Link>
                  <TextField
                    multiline
                    maxRows={10}
                    label="Paste Sanitized Data"
                    onChange={(event) => setCompanyData(event.target.value)}
                    value={companyData}
                  />
                </FormControl>
                {!skipBenefitsRegistry && (
                  <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                    <Link target="_blank" href={benefitsRegistryDataUrl}>
                      Get Benefits Registry Data From{" "}
                      {dataLoaderLoadFromEnvironment}
                    </Link>
                    <TextField
                      multiline
                      label="Paste Benefits Registry Data"
                      maxRows={10}
                      onChange={(event) =>
                        setBenefitsRegistryCompanyData(event.target.value)
                      }
                      value={benefitsRegistryCompanyData}
                    />
                  </FormControl>
                )}
              </FormGroup>
            )}
            <ActionButton
              label="Load data"
              method="POST"
              resource="data_loader"
              id={dataLoaderCompanyId}
              successMessage={"Data loader kicked off!"}
              bodyData={{
                load_from_environment: dataLoaderLoadFromEnvironment,
                company_data: companyData,
                skip_benefits_registry: skipBenefitsRegistry,
                benefits_registry_company_data: benefitsRegistryCompanyData,
              }}
              disabled={!dataLoaderCompanyId}
            />
            <LoadedCompany />
          </FormGroup>
        </Box>
      )}
      <Divider sx={{ borderWidth: 2 }} />

      {!isProduction && (
        <Box sx={{ pb: 4 }}>
          <h4>Company Seeding Tool</h4>
          <div>
            This tool is designed to seed companies in Sage with complex open
            market benefit scenarios. These scenarios:
            <ul>
              <li>
                {" "}
                Target Payroll Companies: The tool is specifically tailored for
                payroll companies, at least for now.
              </li>
              <li>
                {" "}
                Align with Production Parity: The scenarios offered by the tool
                provide a higher degree of parity with production companies, as
                they conform to the business logic of each benefits domain.
              </li>
              <li>
                {" "}
                Accessible in Non-Prod Environments: The scenarios are easily
                accessible in all non-production environments, and they
                particularly enable easier local development workflows.
              </li>
            </ul>
          </div>

          <FormGroup sx={{ mt: 3, maxWidth: "100%" }}>
            <FormControl sx={{ mb: 2 }} size="small">
              <InputLabel id="scenario-select-label">
                Select Scenario
              </InputLabel>
              <Select
                labelId="scenario-select-label"
                value={selectedScenario}
                onChange={(e) => setSelectedScenario(e.target.value)}
                label="Select Scenario"
                disabled={isLoading || scenarios.length === 0}
                sx={{
                  width: "800px",
                  "& .MuiSelect-select": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: "800px",
                      maxHeight: "80vh",
                    },
                  },
                }}
              >
                {scenarios.map((scenario) => (
                  <MenuItem
                    key={scenario.scenario_id}
                    value={scenario.scenario_id}
                    sx={{
                      whiteSpace: "normal",
                      padding: 2,
                      borderBottom: "1px solid #eee",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography
                        component="div"
                        fontWeight="bold"
                        sx={{ mb: 0.5 }}
                      >
                        {scenario.scenario_id}
                      </Typography>
                      {scenario.notes && (
                        <Typography
                          component="div"
                          sx={{
                            color: "text.secondary",
                            fontSize: "0.9em",
                            lineHeight: 1.4,
                            width: "100%",
                            wordBreak: "break-word",
                          }}
                        >
                          {scenario.notes}
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ width: "800px" }}>
              {" "}
              <ActionButton
                label="Seed Scenario"
                method="POST"
                resource="seeding/scenario"
                id={selectedScenario}
                successMessage={`Successfully seeded company with scenario ${selectedScenario}`}
                disabled={!selectedScenario}
                callback={({ data }) => setSeededCompanyInfo(data)}
              />
            </Box>

            {error && (
              <Typography color="error" sx={{ mb: 2 }}>
                Error: {error}
              </Typography>
            )}

            {seededCompanyInfo && (
              <Box sx={{ mt: 2, p: 2, bgcolor: "#f5f5f5", borderRadius: 1 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Successfully Seeded Company:
                </Typography>
                <Typography>
                  <strong>Company ID:</strong>{" "}
                  <Link
                    href={`#/companies/${seededCompanyInfo.company_id}/show`}
                    sx={{ cursor: "pointer" }}
                  >
                    {seededCompanyInfo.company_id}
                  </Link>
                </Typography>
                <Typography>
                  <strong>Company Name:</strong>{" "}
                  {seededCompanyInfo.company_name}
                </Typography>
                <Typography>
                  <strong>Scenario ID:</strong> {seededCompanyInfo.scenario_id}
                </Typography>
              </Box>
            )}
          </FormGroup>
        </Box>
      )}
    </div>
  );
};

UtilityView.propTypes = {};
UtilityView.defaultProps = {};

export default UtilityView;
