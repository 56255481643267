import { React } from "react";
import {
  List,
  Datagrid,
  WithRecord,
  FunctionField,
  FilterList,
  FilterListItem,
  Button,
  TextInput,
  Form,
  Edit,
  Create,
  useRecordContext,
  useGetList,
  useGetOne,
  Loading,
  ReferenceInput,
  TextField,
  AutocompleteInput,
  SaveButton,
  downloadCSV,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { Card, CardContent } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { ActionButton } from "../ActionButton";
/* Has to mirror Event::Names Topic - Justworks update */
const COMPANY_UPDATE = "company_update";
const COMPANY_ADDRESS_UPDATE = "company_address_update";
const MEMBER_ADDED = "member_added";
const MEMBER_UPDATE = "member_update";
const MEMBER_ADDRESS_UPDATE = "member_address_update";
const MEMBER_EMPLOYMENT_STATE_UPDATE = "member_employment_state_update";
const MEMBER_EMPLOYMENT_STATE_TERMINATED = "member_employment_state_terminated";
const COMPANIES_SOURCE = "companies";
const MEMBERS_SOURCE = "members";

const getOpsManAckDate = (record) => {
  return record?.acked_by?.ops_manual_acknowledgment;
};

const getStartDate = (record) => {
  return record?.data?.start_date || "Start date not available";
};

const humanize = (str) => {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

const linkRecordToSageTools = (record, memberName) => {
  const eventName = record.event_name;
  const data = record.data;
  let link = null;
  let label = null;
  switch (eventName) {
    case COMPANY_UPDATE:
      link = `#/${COMPANIES_SOURCE}/${record.data.id}/show`;
      label = data.name;
      break;
    case COMPANY_ADDRESS_UPDATE:
      link = `#/${COMPANIES_SOURCE}/${record.data.company_id}/show/7`;
      label = "Company Address";
      break;
    case MEMBER_ADDED:
      link = `#/${MEMBERS_SOURCE}/${record.data.id}/show`;
      label = data.first_name + " " + data.last_name;
      break;
    case MEMBER_UPDATE:
      link = `#/${MEMBERS_SOURCE}/${record.data.id}/show`;
      label = data.first_name + " " + data.last_name;
      break;
    case MEMBER_ADDRESS_UPDATE:
      link = `#/${MEMBERS_SOURCE}/${record.data.member_id}/show/5`;
      label = memberName;
      break;
    case MEMBER_EMPLOYMENT_STATE_UPDATE:
      link = `#/${MEMBERS_SOURCE}/${record.data.member_id}/show/6`;
      label = memberName;
      break;
    case MEMBER_EMPLOYMENT_STATE_TERMINATED:
      link = `#/${MEMBERS_SOURCE}/${record.data.member_id}/show/6`;
      label = memberName;
      break;
    default:
      throw new Error(`Event name ${eventName} is not valid`);
  }
  return { link, label };
};

const getLinkedEntity = (record, memberName) => {
  const { label, link: href } = linkRecordToSageTools(record, memberName);
  return <Button key={record.data.id} href={href} label={label}></Button>;
};

const getUpdatesTable = (record) => {
  const { updates } = record.data;
  if (!updates) return <h5 key={record.data.id}>Updates not available.</h5>;
  return (
    <table width={"250px"} style={{ borderCollapse: "collapse" }}>
      <thead
        style={{ background: "#F0F0F0", fontSize: "12px", textAlign: "center" }}
      >
        <tr>
          <th>Attribute</th>
          <th>Previous Value</th>
          <th>Current Value</th>
        </tr>
      </thead>
      <tbody key={record.data.id}>
        {Object.keys(updates).map((key, index) => {
          let currUpdate = updates[key];
          return (
            <tr
              key={record.data.id + index}
              style={{ fontSize: "10px", borderBottom: "1px solid lightgrey" }}
            >
              <td>{humanize(key)}</td>
              <td>{currUpdate.previous_value}</td>
              <td>{currUpdate.current_value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const exporter = (events, fetchRelatedRecords) => {
  const datas = events.map((event) => event.data);

  fetchRelatedRecords(datas, "member_id", "members").then((members) => {
    const eventsToExport = events.map((event) => ({
      ...event,
      first_name:
        event.data.first_name || members[event.data?.member_id]?.first_name,
      last_name:
        event.data.last_name || members[event.data?.member_id]?.last_name,
    }));

    return jsonExport(
      eventsToExport,
      {
        headers: ["id", "first_name", "last_name"],
      },
      (err, csv) => {
        downloadCSV(csv, "events");
      }
    );
  });
};

export const PostFilterSidebar = () => {
  const { data: users, isLoading } = useGetList("internal_users", {
    pagination: { page: 1, perPage: 500 },
    sort: { field: "created_at", order: "DESC" },
  });
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 300 }}>
      <CardContent>
        <FilterList label="Assigned To" icon={<InsertEmoticonIcon />}>
          {users.map((user) => (
            <FilterListItem
              key={user.id}
              label={user.first_name + " " + user.last_name}
              value={{ assignment: { assignee_id: user.id } }}
            />
          ))}
        </FilterList>
        {/* Hide events tied to prospective companies */}
        <FilterList label="Status" icon={<AnnouncementIcon />}>
          <FilterListItem
            label="Action Needed"
            value={{
              acked_by: {
                ops_manual_acknowledgment: false,
              },
            }}
          />
          <FilterListItem
            label="Acknowledged"
            value={{
              acked_by: {
                ops_manual_acknowledgment: true,
              },
            }}
          />
        </FilterList>
        <FilterList label="Event Name" icon={<AssignmentIcon />}>
          <FilterListItem
            label="Company Updated"
            value={{
              event_name: COMPANY_UPDATE,
            }}
          />
          <FilterListItem
            label="Company Address Updated"
            value={{
              event_name: COMPANY_ADDRESS_UPDATE,
            }}
          />
          <FilterListItem
            label="Member Added"
            value={{
              event_name: MEMBER_ADDED,
            }}
          />
          <FilterListItem
            label="Member Updated"
            value={{
              event_name: MEMBER_UPDATE,
            }}
          />
          <FilterListItem
            label="Member Address Updated"
            value={{
              event_name: MEMBER_ADDRESS_UPDATE,
            }}
          />
          <FilterListItem
            label="Employment State Updated"
            value={{
              event_name: MEMBER_EMPLOYMENT_STATE_UPDATE,
            }}
          />
          <FilterListItem
            label="Employment State Terminated"
            value={{
              event_name: MEMBER_EMPLOYMENT_STATE_TERMINATED,
            }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};

const AssignmentControl = ({ children }) => {
  const event = useRecordContext();
  const { data, isLoading } = useGetList(
    "assignments",
    {
      filter: { subject_id: event.id, subject_type: "event" },
    },
    { enabled: !!event }
  );

  if (isLoading) {
    return <Loading />;
  }
  const [assigment] = data;
  if (assigment) {
    return <AssignmentEdit assigment={assigment}>{children}</AssignmentEdit>;
  } else {
    return <AssignmentCreate eventId={event.id}>{children}</AssignmentCreate>;
  }
};

const AssignmentCreate = ({ eventId, children }) => {
  return (
    <Create
      title=" "
      actions={false}
      mutationMode="optimistic"
      redirect={false}
      resource="assignments"
      component="div"
    >
      <AssignmentForm
        defaultValues={{
          subject_id: eventId.toString(),
          subject_type: "event",
        }}
      >
        {children}
      </AssignmentForm>
    </Create>
  );
};

const AssignmentEdit = ({ assigment, children }) => {
  return (
    <Edit
      title=" "
      mutationMode="optimistic"
      component="div"
      actions={false}
      redirect={false}
      resource="assignments"
      id={assigment.id}
    >
      <AssignmentForm>{children}</AssignmentForm>
    </Edit>
  );
};

const AssignmentFormNotes = () => {
  return (
    <AssignmentControl>
      <TextInput
        parse={(value) => (value === null ? "" : value)}
        helperText={false}
        source="notes"
        multiline
        label={false}
        fullWidth
        sx={{ minWidth: "200px" }}
      />
    </AssignmentControl>
  );
};

const AssignmentFormAssignee = () => {
  const { data: users, isLoading } = useGetList("internal_users", {
    pagination: { page: 1, perPage: 500 },
    sort: { field: "created_at", order: "DESC" },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <AssignmentControl>
      <ReferenceInput
        label="Assignee"
        reference="internal_users"
        source="assignee_id"
      >
        <AutocompleteInput
          source="assignee_id"
          choices={users}
          label={false}
          optionText={(record) => `${record.first_name} ${record.last_name}`}
          optionValue="id"
        />
      </ReferenceInput>
    </AssignmentControl>
  );
};

const AssignmentForm = ({ defaultValues = {}, children }) => {
  return (
    <Form defaultValues={defaultValues}>
      {children}
      <SaveButton sx={{ padding: "5px", fontSize: "12px" }} label="Save" />
    </Form>
  );
};

const CreatedLinkedEntity = () => {
  const record = useRecordContext();
  const { data, isLoading } = useGetOne("members", {
    id: record.data.member_id,
  });
  if (!record) return null;
  if (isLoading) {
    return <Loading />;
  }

  const memberName = data?.first_name + " " + data?.last_name;
  return getLinkedEntity(record, memberName);
};

const EventList = () => {
  return (
    <div>
      <h1 style={{ marginBottom: 0 }}>
        Clockwork Sync
        <SyncAltIcon style={{ marginLeft: "5px" }} />
      </h1>
      <List
        resource="events"
        filter={{ topic: "justworks_update" }}
        perPage={25}
        // here
        sort={{ field: "created_at", order: "DESC" }}
        aside={<PostFilterSidebar />}
        title="Clockwork Sync"
        exporter={exporter}
      >
        <Datagrid>
          <FunctionField
            source="event_name"
            render={(record) => humanize(record.event_name)}
          ></FunctionField>
          <TextField source="created_at"></TextField>
          <FunctionField
            source="data.start_date"
            label="Start Date"
            render={(record) => getStartDate(record)}
          ></FunctionField>
          <FunctionField
            source="acked_by.ops_manual_acknowledgment"
            label="Acknowledgement Date"
            render={(record) => getOpsManAckDate(record)}
          ></FunctionField>
          <CreatedLinkedEntity label="Created" />
          <WithRecord
            label="Updates"
            render={(record) => getUpdatesTable(record)}
          />
          <AssignmentFormNotes label="Notes" />
          <AssignmentFormAssignee label="Assigned to" />
          <WithRecord
            label="Acknowledgement"
            render={(record) => (
              <ActionButton
                resource="events"
                id={record.id.toString()}
                action="manual_ack"
                label={
                  record && record.acked_by.ops_manual_acknowledgment
                    ? "Acknowledged!"
                    : "Action Needed!"
                }
                method="PUT"
                disabled={
                  !!(record && record.acked_by.ops_manual_acknowledgment)
                }
              ></ActionButton>
            )}
          />
        </Datagrid>
      </List>
    </div>
  );
};

export default EventList;
