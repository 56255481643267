import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-admin";
import { useRecordContext, TextField } from "react-admin";
import { BENEFITS_OPS_DASH_HOST } from "lib/constants";

const MembersDashboardButtonComponent = ({ label, source }) => {
  const record = useRecordContext();

  const openMembersDashboardInNewTab = () => {
    const dashboardUrl = `${BENEFITS_OPS_DASH_HOST}/health/open-market/members/${record.id}`;

    window.open(dashboardUrl, "_blank");
  };

  return (
    <div>
      <TextField label="Go to:" key={label} source={source} sortable={false} />
      <Button
        label="Members Dashboard"
        onClick={openMembersDashboardInNewTab}
      />
    </div>
  );
};

export const MembersDashboardButton = function MembersDashboardButton(props) {
  return (
    <MembersDashboardButtonComponent
      key="MembersDashboardButtonComponent"
      {...props}
    />
  );
};
MembersDashboardButtonComponent.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
