/* eslint-disable react/jsx-props-no-spreading */
import { React } from "react";
import { Button, useRecordContext } from "react-admin";
import { BENEFITS_OPS_DASH_HOST } from "lib/constants";

const QleDashboardLinkComponent = function QleDashboardLinkComponent(props) {
  const qle = useRecordContext(props);

  return (
    <div>
      <Button
        href={`${BENEFITS_OPS_DASH_HOST}/health/open-market/qualifying-life-events/${qle.id}`}
      >
        Show in Dashboard
      </Button>
    </div>
  );
};

const QleDashboardLink = function QleDashboardLink() {
  return <QleDashboardLinkComponent key="QleDashboardLinkComponent" />;
};

export default QleDashboardLink;
