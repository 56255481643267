import React from "react";
import { Button } from "react-admin";
import { BENEFITS_OPS_DASH_HOST } from "lib/constants";

const BulkPlanUploadButtonComponent = ({ label, source }) => {
  const openBulkPlanDashboardToolInNewTab = () => {
    const dashboardUrl = `${BENEFITS_OPS_DASH_HOST}/health/open-market/plans/upload`;

    window.open(dashboardUrl, "_blank");
  };

  return (
    <div>
      <Button
        label="Have multiple plans to upload? Use Bulk Plan Upload Here."
        onClick={openBulkPlanDashboardToolInNewTab}
      />
    </div>
  );
};

export const BulkPlanUploadButton = function BulkPlanUploadButton(props) {
  return (
    <BulkPlanUploadButtonComponent
      key="BulkPlanUploadButtonComponent"
      {...props}
    />
  );
};
