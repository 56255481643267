import { React } from "react";
import {
  DateInput,
  useRecordContext,
  SimpleForm,
  useRefresh,
  SaveContextProvider,
  RecordContextProvider,
  required,
  useDataProvider,
  CheckboxGroupInput,
  useNotify,
  SelectInput,
  TextInput,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import BenefitSpecificComponent from "./components/BenefitSpecificComponent";
import { selectionWindows } from "../InsuranceQuoteRequestCreate/constants";
import { US_STATES } from "components/Blueprint/SelectChoices";

const BENEFIT_TYPE_CHOICES = [
  { name: "Medical", id: "medical" },
  { name: "Dental", id: "dental" },
  { name: "Vision", id: "vision" },
];

const OfferStartDateInput = () => {
  const { setValue } = useFormContext();

  const setDefaultOfferEnd = (e) => {
    const start = e.target.value;
    const date = new Date(start.replace("-", "/"));
    getSelectionWindow(start);
    date.setFullYear(date.getFullYear() + 1);
    date.setDate(date.getDate() - 1);
    const formattedDate =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);
    setValue("offering_end_date", formattedDate);
  };

  const getSelectionWindow = (covStartDate) => {
    if (covStartDate in selectionWindows) {
      setValue(
        "selection_window_start_date",
        selectionWindows[covStartDate].startDate
      );
      setValue(
        "selection_window_end_date",
        selectionWindows[covStartDate].endDate
      );
    }
  };

  return (
    <DateInput
      label="Offering Start Date"
      source="offering_start_date"
      onChange={setDefaultOfferEnd}
      validate={required()}
    />
  );
};

const CreateForm = () => {
  const mutationMode = "pessimistic";
  const { id: companyId } = useRecordContext();
  const { takeAction } = useDataProvider();
  const refresh = useRefresh();

  const { mutate, isLoading } = useMutation((formRecord) => {
    takeAction(
      "company_insurance_quote_requests",
      { action: "create_from_zywave_quote" },
      {
        body: JSON.stringify({ ...formRecord, company_id: companyId }),
      }
    ).then(
      () => {
        refresh();
        notify("Created", { type: "success" });
      },
      (error) => {
        notify(error.body.error, { type: "error", autoHideDuration: 10000 });
      }
    );
  });

  const notify = useNotify();

  return (
    <RecordContextProvider>
      <SaveContextProvider
        value={{ save: mutate, saving: isLoading, mutationMode }}
      >
        <SimpleForm>
          <CheckboxGroupInput
            label="Benefit types"
            source="benefit_types"
            choices={BENEFIT_TYPE_CHOICES}
            defaultValue={["medical"]}
            validate={required()}
          />
          <OfferStartDateInput />
          <DateInput
            label="Offering End Date"
            source="offering_end_date"
            validate={required()}
          />
          <DateInput
            label="Selection Window Start Date"
            source="selection_window_start_date"
            validate={required()}
          />
          <DateInput
            label="Selection Window End Date"
            source="selection_window_end_date"
            validate={required()}
          />
          <TextInput
            label="SIC code"
            source="sic_code"
            validate={required()}
            type="number"
          />
          <SelectInput
            label="State"
            source="state"
            choices={US_STATES}
            validate={required()}
          />
          <TextInput
            label="Zipcode"
            source="zip"
            validate={required()}
            type="number"
          />
          <BenefitSpecificComponent />
        </SimpleForm>
      </SaveContextProvider>
    </RecordContextProvider>
  );
};

const InsuranceQuoteRequestFromZywaveQuote = () => {
  return <CreateForm />;
};

export default InsuranceQuoteRequestFromZywaveQuote;
