import { React, useState } from "react";
import {
  AppBar,
  TitlePortal,
  RichTextField,
  useListController,
  useRedirect,
} from "react-admin";

import { Autocomplete, TextField, Box } from "@mui/material";
import { styled, lighten, darken } from "@mui/system";
import { BENEFITS_OPS_DASH_HOST } from "lib/constants";

const GroupHeader = styled("div")(({ theme }) => ({
  textTransform: "title-case",
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: "0 10px",
  textDecoration: "none",
});

const MultiSearchInput = function MultiSearchInput() {
  const redirect = useRedirect();
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState();

  const { isLoading } = useListController({
    debounce: 500,
    disableSyncWithLocation: true,
    resource: "search",
    filter: { query },
    queryOptions: {
      enabled: !!query && query.length >= 3,
      onSuccess: ({ data }) => {
        setOptions(data);
      },
    },
  });

  const onBlur = () => {
    setQuery("");
    setOptions();
  };

  const handleOptionSelect = (_event, option) => {
    const { resource, id } = option || {};
    setQuery("");
    setOptions();
    if (!id) return;

    redirect("show", resource, id);
  };

  const handleInputChange = (event) => {
    const { type, target } = event || {};
    if (type) {
      setQuery(type === "change" ? target.value.toString() : "");
    }
  };

  return (
    <Autocomplete
      sx={{ minWidth: "400px", width: "25%" }}
      freeSolo
      onBlur={onBlur}
      options={
        !isLoading && options && !options.length
          ? [{ content: "No results" }]
          : options || []
      }
      loading={isLoading}
      filterOptions={(x) => x}
      inputValue={query}
      onInputChange={handleInputChange}
      onChange={handleOptionSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search"
          variant="filled"
          size="small"
          sx={{ backgroundColor: "white" }}
        />
      )}
      getOptionLabel={(option) => option.content}
      groupBy={(option) => option.label}
      renderOption={(props, option) => {
        const { content } = option;
        return (
          <Box component="li" {...props}>
            <RichTextField source="content" record={{ content }} />
          </Box>
        );
      }}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};

const dashboardUrl = `${BENEFITS_OPS_DASH_HOST}/health/open-market/tasks/overview`;

const SearchAppBar = function SearchAppBar() {
  return (
    <AppBar>
      <TitlePortal />
      <MultiSearchInput />
      <div className="dashboard-link">
        <a style={{ padding: "20px", color: "white" }} href={dashboardUrl}>
          Dashboard
        </a>
      </div>
    </AppBar>
  );
};

export default SearchAppBar;
