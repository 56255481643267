import React from "react";
import { Button, Labeled } from "react-admin";
import { useRecordContext } from "react-admin";
import { BENEFITS_OPS_DASH_HOST } from "lib/constants";

const CompanyDashboardButtonComponent = function (props) {
  const record = useRecordContext(props);

  const openCompanyDashboard = () => {
    const dashboardUrl = `${BENEFITS_OPS_DASH_HOST}/health/open-market/companies/${record.id}`;

    window.open(dashboardUrl, "_blank");
  };

  return (
    <div>
      <Labeled>
        <Button label="View in Dashboard" onClick={openCompanyDashboard} />
      </Labeled>
    </div>
  );
};

const CompanyDashboardButton = function CompanyDashboardButton() {
  return <CompanyDashboardButtonComponent key="CompanyDashboardButton" />;
};

export default CompanyDashboardButton;
