import React from "react";
import { Button, Labeled } from "react-admin";
import { useRecordContext } from "react-admin";
import { BENEFITS_OPS_DASH_HOST } from "lib/constants";

const DependentDashboardButtonComponent = function (props) {
  const record = useRecordContext(props);

  const openDependentDashboard = () => {
    const dashboardUrl = `${BENEFITS_OPS_DASH_HOST}/health/open-market/dependents/${record.id}`;

    window.open(dashboardUrl, "_blank");
  };

  return (
    <div>
      <Labeled>
        <Button label="View in Dashboard" onClick={openDependentDashboard} />
      </Labeled>
    </div>
  );
};

const DependentDashboardButton = function DependentDashboardButton() {
  return <DependentDashboardButtonComponent key="DependentDashboardButton" />;
};

export default DependentDashboardButton;
